import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

// import Img from 'gatsby-image';
import Layout from '../components/layout/layout';

const Blog = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulBlogPost {
          edges {
            node {
              # title
              id
              slug
              # publishedDate(formatString: "Do MMMM, YYYY")
              # featuredImage {
              #   fluid(maxWidth: 750) {
              #     ...GatsbyContentfulFluid
              #   }
              # }
              # excerpt {
              #   childMarkdownRemark {
              #     excerpt(pruneLength: 150)
              #   }
              # }
            }
          }
        }
      }
    `
  );
  return (
    <Layout>
      {/* <SEO title="Blog" /> */}
      <p>
        <Link to="/">Go back to the homepage</Link>
      </p>
      <ul className="posts">
        {data.allContentfulBlogPost.edges.map((edge) => {
          return (
            <li className="post" key={edge.node.id}>
              {/* <h2>
                <Link to={`/blog/${edge.node.slug}/`}>{edge.node.title}</Link>
              </h2>
              <div className="meta">
                <span>Posted on {edge.node.publishedDate}</span>
              </div> */}
              <div className="button">
                <Link to={`/~/${edge.node.slug}`}>{edge.node.slug}</Link>
              </div>
            </li>
          );
        })}
      </ul>
    </Layout>
  );
};

export default Blog;
